@font-face {
  font-family: "MyFont";
  src: local("MyFont"),
    url(./fonts/main/Arsenal/Arsenal-Regular.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: "MyFontBold";
  src: local("MyFont"),
    url(./fonts/main/Arsenal/Arsenal-Bold.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
.App {
  width: 100vw;
  height: 100vh;
}
h1 {
  font-family: "MyFontBold";
}
h2 {
  font-family: "MyFontBold";
}

body {
  margin: 0px;
}
