.navContainer {
  position: relative;
  font-family: "MyFont";
  width: 100%;
  border-bottom: solid 0.5px gray;
}
.navContainer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./images/dragon-transparent.png");
  filter: opacity(25%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.navContainer-inside {
  /* This will make it stack on top of the ::before */
  position: relative;
}

.homeContainer {
  position: relative;
  padding: 16px;
}
.homeContainer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./images/dragon-transparent.png");
  filter: opacity(25%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.homeContainer-inside {
  /* This will make it stack on top of the ::before */
  position: relative;
}

.banner {
  font-family: "MyFont";
  width: calc(100% - 36px);
  display: flex;
  padding: 18px;
  text-align: center;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 20;
}

.iconContainer {
  background: none;
  color: inherit;
  border: none;
  padding: 14px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.cardTitle {
  margin: 12px;
  text-align: center;
}

.cardDescription {
  font-weight: 300;
  font-style: italic;
  text-align: center;
}

.footerText {
  font-weight: 500;
}

.emoji {
  font-size: 20px;
}

.slick-next:before {
  content: none !important;
}
.slick-prev:before {
  content: none !important;
}

.hoursText {
  display: flex;
  align-items: center;
}

.day {
  font-family: "MyFontBold";
  text-align: center;
  width: 50%;
}
.hours {
  text-align: center;
  width: 50%;
  font-weight: 300;
}
